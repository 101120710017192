<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-datepicker
            :range="true"
            defaultStart="-1M"
            defaultEnd="0M"
            label="점검기간"
            v-model="searchParam.period">
          </c-datepicker>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <!-- 관리감독자 -->
          <c-field
            label="관리감독자"
            name="checkUserId"
            :isDeptManager="true"
            v-model="searchParam.checkUserId">
          </c-field>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <!-- 부서 -->
          <c-dept
            :isFirstValue="false"
            type="search"
            label="부서"
            name="checkDeptCd"
            v-model="searchParam.checkDeptCd">
          </c-dept>
        </div>
      </template>
    </c-search-box>
    <div class="card cardcontents">
      <div class="card-body qtable-card-body">
        <q-table
          grid
          class="qtable-card"
          title=""
          :data="grid.data"
          :columns="grid.columns"
          hide-header
          hide-bottom
          :rows-per-page-options="[0]"
          virtual-scroll
        >
          <template v-slot:item="props">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
              <q-card class="mobileTableCardLayer" @click="linkClick(null,props.row)">
                <q-card-section class="grid-card-etc">
                  <div class="txt-box-grid text-gray-box">
                    {{ props.row.checkDeptUserName }}
                  </div>
                </q-card-section>
                <q-card-section>
                  <div class="text-grid-title q-mt-sm q-mb-xs">{{ props.row.safetyCheckName }}</div>
                </q-card-section>
                 <q-card-section class="grid-card-etc">
                  <div class="text-grid-etc">
                    {{ props.row.checkDate }} 
                  </div>
                </q-card-section>
              </q-card>
            </div>
          </template>
        </q-table>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3" v-if="grid.data.length === 0">
          <q-card class="mobileTableCardLayer">
            <q-card-section horizontal class="text-center">
              <q-card-section class="q-pt-xs">
                <div class="text-grid-title q-mt-sm q-mb-xs">데이터가 존재하지 않습니다.</div>
              </q-card-section>
            </q-card-section>
          </q-card>
        </div>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import mixinFooterSearch from '@/js/mixin-footer-search-common'
export default {
  mixins: [mixinFooterSearch],
  name: "safetyCheck",
  props: {
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: "plantName",
            field: "plantName",
            style: 'width:100px',
            label: "사업장",
            align: "center",
            sortable: false,
          },
          {
            name: "safetyCheckName",
            field: "safetyCheckName",
            style: 'width:200px',
            label: "점검명",
            align: "left",
            type: "link",
            sortable: false,
          },
          {
            name: "checkDeptName",
            field: "checkDeptName",
            style: 'width:100px',
            label: "부서",
            align: "center",
            sortable: false,
          },
          {
            name: "checkStatusName",
            field: "checkStatusName",
            label: "진행단계",
            style: 'width:100px',
            align: "center",
            sortable: false,
          },
          {
            name: 'approvalStatusName',
            field: 'approvalStatusName',
            label: '결재진행단계',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            name: "checkDate",
            field: "checkDate",
            style: 'width:100px',
            label: "점검월",
            align: "center",
            sortable: false,
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        month: '',
        checkStatusCd: null,
        checkDeptCd: '',
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        isFull: true,
        param: {},
        closeCallback: null,
      },
    };
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.safetycheck.plan.list.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */ 
    linkClick(evt, row) {
      this.popupOptions.title = '관리감독자 업무일지 상세'; // 업무일지 상세
      this.popupOptions.target = () => import(`${"./safetyCheckDetail.vue"}`);
      this.popupOptions.param = {
        safetyCheckId: row ? row.safetyCheckId : '',
      };
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(type) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    getColorStatus(cd) {
      var cls = '';
      switch(cd) {
        case 'MCSC000005': // 계획수립
          cls = 'txt-box-grid text-orange-box';
          break;
        case 'MCSC000010': // 점검중
          cls = 'txt-box-grid text-primary-box';
          break;
        case 'MCSC000015': // 점검완료
          cls = 'txt-box-grid text-positive-box';
          break;
        case 'MCSC000020': // 지연
          cls = 'txt-box-grid text-red-box';
          break;
      }
      return cls;
    },
    getColorStatus2(cd) {
      var cls = '';
      switch(cd) {
        case 'ASC0000001': // 계획수립
          cls = 'text-primary';
          break;
        case 'ASC0000002': // 점검중
          cls = 'text-positive';
          break;
        case 'ASC9999999': // 점검완료
          cls = 'text-orange-10';
          break;
      }
      return cls;
    },
  },
};
</script>